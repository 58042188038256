import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const diplomasSwiper = new Swiper(".diplomas-swiper", {
  slidesPerView: 1,
  spaceBetween: 16,
  breakpoints: {
    768: {
      spaceBetween: 20,
      slidesPerView: 2,
    },
    992: {
      spaceBetween: 19,
      slidesPerView: 3,
      centeredSlides: false,
    },
  },
  navigation: {
    prevEl: ".prev",
    nextEl: ".next",
  },
  pagination: {
    el: ".ctrl-counter2",
    type: "fraction",
  },
  autoHeight: true,
});
const swiperBenefits = new Swiper(".benefits-swiper", {
  slidesPerView: 1.5,
  spaceBetween: 20,
  autoHeight: true,
  loop: true,
  centeredSlides: false,
});

const swiperPartners = new Swiper(".partners-swiper", {
  slidesPerView: 1,
  spaceBetween: 16,
  breakpoints: {
    768: {
      spaceBetween: 20,
      slidesPerView: 2,
    },
  },
  navigation: {
    prevEl: ".partners-list .prev",
    nextEl: ".partners-list .next",
  },
  autoHeight: true,
  centeredSlides: true,

  pagination: {
    el: ".partners-list .ctrl-counter",
    type: "fraction",
  },
});
const swiperPromocode = new Swiper(".promocode-swiper", {
  slidesPerView: 1,
  spaceBetween: 16,
  breakpoints: {
    768: {
      spaceBetween: 20,
      slidesPerView: 2,
    },
  },
  navigation: {
    prevEl: ".prev",
    nextEl: ".next",
  },
  pagination: {
    el: ".ctrl-counter2",
    type: "fraction",
  },
  autoHeight: true,
});
const swiperBlog = new Swiper(".swiper-articles", {
  slidesPerView: 1,
  spaceBetween: 16,
  breakpoints: {
    768: {
      spaceBetween: 20,
      slidesPerView: 2,
    },
  },
  navigation: {
    prevEl: ".prev",
    nextEl: ".next",
  },
  pagination: {
    el: ".swiper-articles .ctrl-counter2",
    type: "fraction",
  },
  autoHeight: true,
});
const swiperServices = new Swiper(".services-swiper", {
  slidesPerView: 1,
  spaceBetween: 20,
  breakpoints: {
    768: {
      spaceBetween: 20,
      slidesPerView: 2,
    },
  },
  navigation: {
    prevEl: ".prev",
    nextEl: ".next",
  },
  pagination: {
    el: ".ctrl-counter2",
    type: "fraction",
  },
  autoHeight: true,
});
const swiperPartnersGallery = new Swiper(".partnersPage-swiper", {
  slidesPerView: 1,
  spaceBetween: 16,
  pagination: {
    el: ".partnersPage-swiper .ctrl-counter2",
    type: "fraction",
  },
  navigation: {
    prevEl: ".partnersPage-swiper .prev",
    nextEl: ".partnersPage-swiper .next",
  },
  autoHeight: false,
  centeredSlides: true,

  breakpoints: {
    768: {
      spaceBetween: 20,
      slidesPerView: 2,
    },
    992: {
      spaceBetween: 70,
      slidesPerView: 2,
      loop: true,
      pagination: {
        el: "#partners .partners-counter",
        type: "fraction",
      },
    },
  },
});

const swiperGallery = new Swiper(".gallery-swiper", {
  slidesPerView: 1,
  spaceBetween: 16,
  pagination: {
    el: ".gallery-swiper .ctrl-counter2",
    type: "fraction",
  },
  navigation: {
    prevEl: ".gallery-swiper .prev",
    nextEl: ".gallery-swiper .next",
  },
  breakpoints: {
    768: {
      spaceBetween: 20,
      slidesPerView: 2,
    },
    992: {
      slidesPerView: "auto",
      spaceBetween: 70,
    },
  },
  loop: true,
  autoHeight: true,
  centeredSlides: true,
  on: {
    slideChange: function () {
      updateCursorCounter(this.realIndex + 1, this.slides.length);
    },
  },
});
const swiperTreatment = new Swiper(".treatment-swiper", {
  slidesPerView: 1,
  spaceBetween: 16,
  pagination: {
    el: "#treatment .ctrl-counter2",
    type: "fraction",
  },
  navigation: {
    prevEl: "#treatment .prev",
    nextEl: "#treatment .next",
  },
  breakpoints: {
    992: {
      slidesPerView: "auto",
      spaceBetween: 21,
      pagination: {
        el: ".treatment-swiper .partners-counter",
        type: "fraction",
      },
    },
  },

  on: {
    slideChange: function () {
      updateCursorCounter(this.realIndex + 1, this.slides.length);
    },
  },
});

document.addEventListener("DOMContentLoaded", function () {
  const cursorArrow = document.getElementById("cursorArrow");

  if (!cursorArrow) return; // Перевірка наявності елементу

  const screenWidthHalf = window.innerWidth / 2;

  document.addEventListener("mousemove", function (e) {
    cursorArrow.style.transform = `translate(${
      e.clientX - cursorArrow.offsetWidth / 2
    }px, ${e.clientY - cursorArrow.offsetHeight / 2}px)`;

    if (e.clientX < screenWidthHalf) {
      cursorArrow.classList.add("is-active");
    } else {
      cursorArrow.classList.remove("is-active");
    }
  });

  const swiperEls = document.querySelectorAll(".swiper-js");

  swiperEls.forEach((swiperEl) => {
    swiperEl.addEventListener("click", function (event) {
      if (window.innerWidth < 768) return;

      const clickX = event.clientX;

      const swiperInstance = swiperEl.swiper;

      if (!swiperInstance) return; // Перевірка, чи існує Swiper

      if (clickX < screenWidthHalf) {
        swiperInstance.slidePrev();
      } else {
        swiperInstance.slideNext();
      }
    });
  });
  const blogSwiper = document.querySelector(".blog-swiper");

  if (blogSwiper) {
    let token =
      "EABBQaexYIQMBOyk6YvuN8PPoUtEqeLETGSewD3Th4ZAciqQdRtHcj6E5SF8vskSTOZAFxHKv3YE4dr2POQazz2zktGSMaiszhZChfGAaASLZCwFiwU4xtKakan0vwpfmMb4M4rJMs2ZAgo9zgh6qwyuBjAVOQXEZBZB0q7AUtoks9hbbBFZBrn1gviOW";
		let id = "17841462437832873"; 
			if(settings.fetchToken || settings.fetchID){
				token = settings.fetchToken;
				id = settings.fetchID;
			}


    fetch(
      `https://graph.facebook.com/v20.0/${id}/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=${token}`
    )
      .then((response) => response.json())
      .then((data) => {
        const posts = data.data.map((post) => {
          let imageUrl = "";
          if (
            post.media_type === "IMAGE" ||
            post.media_type === "CAROUSEL_ALBUM"
          ) {
            imageUrl = post.media_url;
          } else if (post.media_type === "VIDEO") {
            imageUrl = post.thumbnail_url;
          }
          return {
            image: imageUrl,
            permalink: post.permalink,
            caption: post.caption,
          };
        });

        let wrapper = document.querySelector(".blog-list.swiper-wrapper");
        posts.forEach((post) => {
          let li = document.createElement("li");
          li.classList.add("swiper-slide");
          li.innerHTML = `
							<a class="blog-list" href="${post.permalink}" target="_blank">
								<div style="background: linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('${
                  post.image
                }');" class="blog-list__thumb"></div>
								<div class="blog-list--overlay">
									${
                    post.caption && post.caption.length > 50
                      ? post.caption.slice(0, 50) + "..."
                      : post.caption || ""
                  }
								</div>
							</a>
						`;
          wrapper.appendChild(li);
        });
      })
      .then(() => {
        // Перевірка наявності свайпера перед оновленням курсора

        const swiperBlog = new Swiper(".blog-swiper", {
          slidesPerView: 1,
          spaceBetween: 16,
          pagination: {
            el: ".ctrl-counter2",
            type: "fraction",
          },
          navigation: {
            prevEl: ".blog-swiper .prev",
            nextEl: ".blog-swiper .next",
          },
          breakpoints: {
            768: {
              spaceBetween: 20,
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 5,
              spaceBetween: 70,
              pagination: {
                el: ".blog-swiper .blog-counter",
                type: "fraction",
              },
              navigation: {
                prevEl: ".blog-swiper .prev",
                nextEl: ".blog-swiper .next",
              },
            },
          },
          loop: true,
          autoHeight: true,
          on: {
            slideChange: function () {
              updateCursorCounter(this.realIndex + 1, this.slides.length);
            },
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const treatmentSwiper = document.querySelector(".treatment-swiper");
  if (treatmentSwiper) {
  }
});

function updateCursorCounter(currentSlide, totalSlides) {
  const cursorCounter = document.querySelector(".partners-counter");
  cursorCounter.textContent = currentSlide + " / " + totalSlides;
}
