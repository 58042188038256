//$(document).ready(function () {
//    $(".read-more").click(function () {
//        $(this).next().slideToggle(400);
//
//        if ($(this).text() === 'читати більше') {
//            $(this).text('приховати');
//        } else {
//            $(this).text('читати більше');
//        }
//
//    });
//});

$(document).ready(function () {
    $(".read-more").click(function () {
        if ($(this).next().hasClass('read-more-wrapper') || $(this).next().hasClass('service-more') || $(this).next().hasClass('mob-read-more')) {
            $(this).next().slideToggle(400);
        } else if ($(this).parent().next().hasClass('read-more-wrapper') || $(this).parent().next().hasClass('service-more') || $(this).parent().next().hasClass('mob-read-more')) {
            $(this).parent().next().slideToggle(400);
        }

        if ($(this).text() === 'читати більше') {
            $(this).text('приховати');
        } else {
            $(this).text('читати більше');
        }
    });
});
