import throttle from "lodash.throttle";
import {WOW} from "wowjs/dist/wow.min";
import refs from "./refs";

const {bodyEl} = refs;

const throttledHandleResize = throttle(handleResize, 200);

let currentBackdrop = null;



export const showBackdrop = (backdrop, hideOnResize = false) => {
    if (!backdrop) {
        return;
    }
    disableBodyScroll();

    backdrop.removeClass("is-hidden");
    backdrop.on("click", handleBackdropClick);
    $(window).on("keydown", handleKeyDown);
    currentBackdrop = backdrop;

    if (hideOnResize) {
        $(window).on("resize", throttledHandleResize);
    }
};

export const hideBackdrop = (backdrop) => {
    if (!backdrop) {
        return;
    }

    enableBodyScroll();

    backdrop.addClass("is-hidden");
    backdrop.removeClass("click", handleBackdropClick);
    $(window).off("keydown", handleKeyDown);
    $(window).off("resize", throttledHandleResize);

    currentBackdrop = null;
};

function handleBackdropClick(e) {
    if (e.target === e.currentTarget) {
        hideBackdrop(currentBackdrop);
    }
}

function handleKeyDown(e) {
    if (e.key === "Escape") {
        hideBackdrop(currentBackdrop);
    }
}

function handleResize() {
    const {innerWidth} = window;

    if (innerWidth >= 768) {
        hideBackdrop(currentBackdrop);
    }
}

export function enableBodyScroll() {
    bodyEl.css("overflow-y", "scroll");
}

export function disableBodyScroll() {
    bodyEl.css("overflow-y", "hidden");
}




/// код для партнерів
document.addEventListener('DOMContentLoaded', function () {
    // Перевірка наявності елемента з класом .for-patient_wrapper та елемента <ul> всередині нього
    const wrapperElement = document.querySelector('.for-patient_wrapper');
    // const priceNav = document.querySelector('.prices-nav');



    const listElement = wrapperElement ? wrapperElement.querySelector('ul') : null;
    // const listElementNav = priceNav ? priceNav.querySelector('ul') : null;


    if (wrapperElement && listElement) {
        const listItems = listElement.querySelectorAll('li');

        // Додаємо клас is-active першому елементу списку за замовчуванням
        const firstItem = listItems[0]
        firstItem.classList.add('is-active');

        listItems.forEach(item => {
            item.addEventListener("click",(e) => {
                const $this = $(e.currentTarget)
                if($this.hasClass('is-active')){
                    $this.nextAll("li").removeClass("is-active")
                }
                else {
                    $this.addClass("is-active")
                    $this.prevAll("li").addClass("is-active")
                }
            })
        })
    }
});
/// код для цін та single-service
document.addEventListener('DOMContentLoaded', function() {
    // Перевірка чи є елемент .prices-nav на сторінці
    const pricesNav = document.querySelector('.prices-nav');
    if (pricesNav) {
        const priceItems = pricesNav.querySelectorAll('.prices-nav__item');

        // Function to toggle 'is-active' class for prices
        function togglePriceActiveClass(index) {
            // Remove 'is-active' class from all price items
            priceItems.forEach(item => {
                item.classList.remove('is-active');
            });

            // Add 'is-active' class to the clicked price item
            priceItems[index].classList.add('is-active');

            // Add 'is-active' class to all previous price items
            for (let i = 0; i < index; i++) {
                priceItems[i].classList.add('is-active');
            }
        }

        // Add click event listener to each price item
        priceItems.forEach((item, index) => {
            item.addEventListener('click', function() {
                togglePriceActiveClass(index);
            });
        });

        // Activate the first price item by default
        togglePriceActiveClass(0);
    }

    // Перевірка чи є елемент .service-nav на сторінці
    const servicesNav = document.querySelector('.service-nav');
    if (servicesNav) {
        const serviceItems = servicesNav.querySelectorAll('.service-nav__item');

        // Function to toggle 'is-active' class for services
        function toggleServiceActiveClass(index) {
            // Remove 'is-active' class from all service items
            serviceItems.forEach(item => {
                item.classList.remove('is-active');
            });

            // Add 'is-active' class to the clicked service item
            serviceItems[index].classList.add('is-active');

            // Add 'is-active' class to all previous service items
            for (let i = 0; i < index; i++) {
                serviceItems[i].classList.add('is-active');
            }
        }

        // Add click event listener to each service item
        serviceItems.forEach((item, index) => {
            item.addEventListener('click', function() {
                toggleServiceActiveClass(index);
            });
        });

        // Activate the first service item by default
        toggleServiceActiveClass(0);
    }
});






$("document").ready(function () {
    bodyEl.css("visibility", "visible");

    new WOW().init();
});
