import "bootstrap/dist/css/bootstrap.css";
import "modern-normalize/modern-normalize.css";
import 'intl-tel-input/build/css/intlTelInput.min.css';

import "./utils";
import "./swiper";
import "./menu";
import "./refs";
import "./readMore";
import "./topScroll";
import "./forPatient";
import "./lockPreloader";

import "../css/main.scss";
