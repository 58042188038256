let titleList = document.querySelectorAll(".heading-nav__item .heading-nav__link");
let blocks = document.querySelectorAll(".content-for-patient__item");
if(titleList.length == 0 || blocks.length == 0){
	blocks = document.querySelectorAll(".single-services-list__item");
	titleList = document.querySelectorAll(".service-nav__item .service-nav__title");
}
if(titleList.length == 0 || blocks.length == 0){
    blocks = document.querySelectorAll(".prices__item");
	titleList = document.querySelectorAll(".prices-nav__item .prices__title");
}



let prevIndex = 1
const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if(entry.target.getBoundingClientRect().top < 0 && !entry.isIntersecting){
				
				prevIndex = parseInt(entry.target.id) + 1;
			}else if(entry.target.getBoundingClientRect().top < 0){

				prevIndex = parseInt(entry.target.id) ;
			}

      addToActive(prevIndex);
    });
  },
  { threshold: 0 }
);
window.onload = () => {
  addToActive(prevIndex);
};
let addToActive = (toIndex) => {
  titleList.forEach((title) => {
    title.parentNode.classList.remove("is-active");
  });
  titleList.forEach((title, index) => {
    if (index < toIndex) {
      title.parentNode.classList.add("is-active");
    }
  });
};
blocks.forEach((block) => {
  observer.observe(block);
});
